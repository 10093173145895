const logisticsApi = {
  //物流列表
  logisticsList: '/logistics/list',
  //编辑物流列表
  logisticsEdit: '/logistics/edit',
  //id获取物流详情
  logisticsDetail: '/logistics/getById',
  //发货地址选项
  logisticsShipper: '/logistics/shipperList',
  // 物流详情
  logisticstrace: '/logistics/getByLogisticsId'
}



export default logisticsApi
