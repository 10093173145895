import request from "@/utils/request";
import logisticsApi from '@/const/apiUrl/logistics'
import method from "@/const/request/requestMethod";

//运单列表
export function logisticsList(data) {
  return request({
    url: logisticsApi.logisticsList,
    method: method.GET,
    data: data
  })
}
//编辑物流列表
export function logisticsEdit(params) {
  return request({
    url: logisticsApi.logisticsEdit,
    method: method.POST,
    data: params,
  })
}
//根基id获取运单详情
export function logisticsDetail(params) {
  return request({
    url: logisticsApi.logisticsDetail,
    method: method.GET,
    data: params
  })
}
//发货地址选项
export function logisticsShipper(params) {
  return request({
    url: logisticsApi.logisticsShipper,
    method: method.GET,
    data: params
  })
}


export function logisticsTrace(params) {
  return request({
    url: `${logisticsApi.logisticstrace}?logisticsId=${params}`,
    method: method.GET
  })
}
