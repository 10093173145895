<template>
  <!-- 角色管理 -->
  <div class="container" style="margin-top: 8px;">
    <div class="nav">
      <!-- 查询 -->
      <div class="OperInput">
        <div class="search_box">
          <div class="search_main">
            <div class="status">
              <div>运单时间</div>
              <div>
                <el-date-picker v-model="datatime" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" value-format="yyyy-MM-dd 00:00:00">
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="search_main">
            <div class="status">
              <div>快递公司</div>
              <div>
                <el-select v-model="queryinfo.expressCode" placeholder="请选择快递公司">
                  <el-option value="" label="全部"></el-option>
                  <el-option :label="item.expressName" :value="item.expressCode" v-for="item in expressList"
                    :key="item.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="search_main">
            <div class="status">
              <div>运单号</div>
              <div>
                <el-input style="width: 260px" v-model="queryinfo.expressNo" placeholder="请输入运单号"></el-input>
              </div>
            </div>
          </div>
          <div class="search_main">
            <div class="status">
              <div>订单号</div>
              <div>
                <el-input style="width: 260px" v-model="queryinfo.orderCombineNo" placeholder="请输入订单号"></el-input>
              </div>
            </div>
          </div>
          <div class="search_main">
            <div class="status1" style="display: flex">
              <div class="demandBtn" style="margin-top:10px;" @click="search">
                <img src="../../assets/images/search.png" alt="" /> 查询
              </div>
              <div class="resetBtn" style="margin-top:10px;" @click="reset">
                <img src="../../assets/images/reset.png" alt="" />重置
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 查询 -->
    </div>
    <div style="height: 17px"></div>
    <div class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;" :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px', height: '80px' }">
          <el-table-column prop="expressNo" label="运单号" width="150" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="生成运单时间" width="160" align="center">
          </el-table-column>
          <el-table-column prop="orderCombineNo" label="关联总订单号" width="220" align="center">
            <template slot-scope="scope">
              <div class="orderCombineNo" @click="Jump(scope.row.orderCombineId)">{{ scope.row.orderCombineNo }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="shipper" label="发货人" width="120" align="center">
          </el-table-column>
          <el-table-column prop="shipperPhone" label="发货手机号" width="180" align="center">
          </el-table-column>
          <el-table-column prop="shippingAddress" label="发货地址" width="320" align="center">
          </el-table-column>
          <el-table-column prop="freightAmountStr" label="运费" align="center" width="100">
          </el-table-column>
          <el-table-column prop="expressName" label="快递公司" align="center" width="180">
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="roleEdit(scope.row.id)">
                  <img style="width: 16px;height: 16px;" src="../../assets/images/edit.png">
                </div>
                <div class="waybill" @click="roleDetail(scope.row.id)">
                  <img style="width: 16px;height: 16px;" src="../../assets/images/waybill.png">
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 18px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
    <el-dialog :visible.sync="dialogVisible" width="40%" >
      <div class="block">
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
            :type="activity.type" :color="activity.color" :size="activity.size" :timestamp="activity.ftime">
            {{ activity.context }}
          </el-timeline-item>
        </el-timeline>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { logisticsList, logisticsTrace } from "@/api/logistics";
import { expressList } from "@/api/express";
import "../../font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        endTime: "", //搜索结束时间
        expressCode: "", //物流公司
        expressNo: "", //物流单号
        orderCombineNo: "", //订单号
        currPage: "", //分页
        startTime: '' // 搜索开始时间
      },
      tableData: [],
      dialogVisible: false, // 物流详情
      total: null,
      expressList: [], //快递公司列表
      datatime: [], //时间处理
      activities: []
    };
  },
  activated() {
    this.list();
    //快递公司列表查询
    expressList().then((res) => {
      this.expressList = res.data.list;
    });
  },
  methods: {
    //运单列表
    list() {
      logisticsList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //搜索
    search() {
      this.queryinfo.startTime = this.datatime[0];
      this.queryinfo.endTime = this.datatime[1];
      this.queryinfo.currPage=""
      this.total=0
      this.list();
    },
    // 重置
    reset() {
      this.queryinfo.endTime = "", //搜索结束时间
        this.queryinfo.expressCode = "", //物流公司
        this.queryinfo.expressNo = "", //物流单号
        this.queryinfo.orderCombineNo = "", //订单号
        this.queryinfo.currPage = "", //分页
        this.queryinfo.startTime = '' // 搜索开始时间
      this.queryinfo.datatime = "" //时间处理
      this.queryinfo.currPage = ""
      this.list()
    },

    //编辑
    roleEdit(id) {
      this.$router.push({
        path: "/logistics/detail",
        query: {
          id: id,
        },
      });
    },

    // 物流详情
    roleDetail(id) {
      logisticsTrace(id).then(res => {
        if(res.status==10000){
          this.dialogVisible = true
          this.activities = res.data
          this.activities=  this.activities.map((item,index)=>{
              if(index==0){
                item.color='#279DFB'
              }else{
                item.color='#919191'
              }
              return item
        })
        }
      })
    },

    // 跳转
    Jump(id) {
      this.$router.push({
        path: "/order/detail",
        query: {
          id: id,
        },
      });
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";

.nav {
  padding-left: 20px;
  padding-bottom: 21px;
  padding-top: 0px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>
<style lang="less">
.v-modal {
  position: relative !important;
}

.orderCombineNo {
  color: #0066ff;
}

/deep/ .cell {
  text-align: center;
}

.el-table__body-wrapper::-webkit-scrollbar {
  /*width: 0;宽度为0隐藏*/
  width: 2px;
  height: 10px;
}

.waybill {
  width: 60px;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background: #18BEA4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
  margin-right: 8px;
  cursor: pointer;
}
</style>
